import "./rodape.css";
import { MdPlace } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { IoLogoFacebook } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";


const Rodape = () => {
  return (
    <footer>
      <div class="top_header">
        <section>
          <span>
            <i class="fa fa-map-marker">
                <MdPlace size={40} color="white"/>
            </i>
          </span>
          <span>Santo Antônio do Retiro /MG - 39538-000</span>
        </section>
        <section>
          <span>
            <i class="fa fa-phone">
             <MdLocalPhone size={40} color="white"/>
            </i>
          </span>
          <span>(38) 99835-6065</span>
        </section>
        <section>
          <span>
            <i class="fa fa-envelope">
             <FiMail size={40} color="white"/>
            </i>
          </span>
          <span>geraisnetretiro@gmail.com</span>
        </section>
      </div>
      <span class="border-shape"></span>
      <div class="bottom_content">
        <section>
        <a href="https://wa.me/message/5H2T5UFMH4AWJ1" target="_blank">
            <i class="fa fa-facebook">
            <IoLogoWhatsapp size={50}/>
            </i>
          </a>
          <a href="https://www.instagram.com/gerais_net/" target="_blank">
            <i class="fa fa-instagram">
            <AiFillInstagram size={50}/>
            </i>
          </a>
          <a href="#">
            <i class="fa fa-telegram">
            <FaTelegram size={50}/>
            </i>
          </a>
        </section>
        <section>
          <a href="#home">Home</a>
          <a href="#planos">Nossos Planos</a>
          <a href="#QuemSomos">Quem Somos</a>
        </section>
      </div>
      <div class="copyright">
        Copyright © 2024 geraisnet.com.br - All rights reserved
      </div>
    </footer>
  );
};

export default Rodape;
