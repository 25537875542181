import "./style.css";
import "./styloCards.css";
import "./styloParallax.css";

import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import { MdNextPlan } from "react-icons/md";
import { LuPhoneCall } from "react-icons/lu";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { GiCube } from "react-icons/gi";
import SlideShow from "./component/slideShow";
import CentralAssinante from "./component/centralAssinante";
// Exemplo de importação em um componente React
import "@fontsource/roboto";
import AreaAjuda from "./component/areaAjuda";
import Rodape from "./component/rodape";

export default function Home() {
  return (
    <div className="container-pg">
      <div className="section1" id="home">
        <div className="menu">
          <h1 className="title">
            <img
              className="logo"
              alt="imagem"
              src="/images/Logo-Gerais-Branco.png"
            />
          </h1>
          <div className="nav">
            <a className="btn" style={{ fontFamily: 'Roboto' }} href="#QuemSomos">
              Quem Somos
            </a>
            <a className="btn" href="#planos">
              Planos
            </a>
            <a
              className="btn assinante"
              id="assinante"
              href="http://geraisnet.ispfycloud.com.br/"
              target="_blank"
            >
              <FaUser size={17} className="icon-home-page icon-assinante" />
              Central assinante
            </a>
          </div>
        </div>
        <SlideShow />
        <AreaAjuda/>
      </div>
<div className="contato-home-page">
          <div className="item-home-page">
            <div>
              {" "}
              <LuPhoneCall size={35} className="icon-home-page" />
            </div>
            <h2>Ligue agora</h2>
            <h1>(38) 99835-6065</h1>
          </div>
          <div className="item-home-page">
            <div>
              {" "}
              <FaArrowDown size={35} className="icon-home-page" />
            </div>
            <h2>confira e assine</h2>
            <h1>Nossos planos</h1>
          </div>
          <div className="item-home-page">
            <div>
              {" "}
              <BsWhatsapp size={35} className="icon-home-page" />
            </div>
            <h2>Fale conosco</h2>
            <h1>Pelo whatsapp</h1>
          </div>
        </div>
      <section class="fixe-bg pl_img1">
        <div className="box">
          <h2 className="box-h2">Confira nossos planos.</h2>
        </div>
      </section>
      
      <div className="content" id="planos">
        <div className="box">
          <div className="plans">
            <div className="plan">
              <div className="rocket-cc">
                {" "}
                <img
                  className="rocket"
                  src="/images/rocket.png"
                  alt="rocket"
                ></img>
              </div>
              <h2 className="plan__title">16 Mega</h2>
              <h3 className="plan__subtitle">Basic Plus - <strong>Wireless</strong></h3>
              <p className="plan__price">
               $60.00<span>/mês</span>
              </p>
              <p className="plan__description">
                Entre em contato conosco para obter mais informações sobre
                nossos planos de internet e começar a navegar mais rápido hoje
                mesmo!
              </p>
              <a
                href="https://wa.me/message/5H2T5UFMH4AWJ1"
                target="_blank"
                className="plan__button"
              >
                Contratar Plano
              </a>
            </div>
            <div className="plan">
              <div className="rocket-cc">
                {" "}
                <img
                  className="rocket"
                  src="/images/rocket.png"
                  alt="rocket"
                ></img>
              </div>
              <h2 className="plan__title">30 Mega</h2>
              <h3 className="plan__subtitle">Basic Plus - <strong>Wireless</strong></h3>
              <p className="plan__price">
               $80.00<span>/mês</span>
              </p>
              <p className="plan__description">
                Entre em contato conosco para obter mais informações sobre
                nossos planos de internet e começar a navegar mais rápido hoje
                mesmo!
              </p>
              <a
                href="https://wa.me/message/5H2T5UFMH4AWJ1"
                target="_blank"
                className="plan__button"
              >
                Contratar Plano
              </a>
            </div>
            <div className="plan">
              <div className="rocket-cc">
                {" "}
                <img
                  className="rocket"
                  src="/images/rocket.png"
                  alt="rocket"
                ></img>
              </div>
              <h2 className="plan__title">50 Mega</h2>
              <h3 className="plan__subtitle">Basic Plus - <strong>Fibra</strong></h3>
              <p className="plan__price">
                $60.00<span>/mês</span>
              </p>
              <p className="plan__description">
                Entre em contato conosco para obter mais informações sobre
                nossos planos de internet e começar a navegar mais rápido hoje
                mesmo!
              </p>
              <a
                href="https://wa.me/message/5H2T5UFMH4AWJ1"
                target="_blank"
                className="plan__button"
              >
                Contratar Plano
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="fixe-bg pl_img2">
        <div className="box">
          <h2 className="box-h2"> Você no controle.</h2>
        </div>
      </section> */}
      <div className="content">
        <div className="box">
        </div>
      </div>

        <section className="fixe-bg pl_img3">
        <div className="box">
          <h2 className="box-h2">Quem somos</h2>
        </div>
    </section> 
      <div className="content" id="QuemSomos">
        <div className="box">
          <div className="resumo-da-empresa">
            <div className="div-logo-empresa">
              <img
                className="logo-resumo-empresa"
                src="/images/Logo-Gerais-Branco.png"
              ></img>
            </div>
            <p>
              A GeraisNet é uma provedora de internet com sede no Riacho Fundo,
              cujo crescimento desde sua origem humilde em Brejo Grande a levou
              a expandir suas operações. Atualmente, a empresa oferece seus
              serviços não apenas em Santo Antônio do Retiro, mas também em
              cidades vizinhas como Mato Verde e Monte Azul, além de diversas
              outras comunidades. Reconhecida pelo compromisso em fornecer
              conectividade confiável e acessível, a GeraisNet continua a
              expandir sua rede, impulsionando o desenvolvimento e a inclusão
              digital em áreas rurais.
            </p>
            <section>
             
              <div className="section-cubos">
                <div className="cubos">
                  <div>
                    <GiCube color="#fb7c05" size={35} className="icon-home-page" />
                  </div>
                  <h2>TECNOLOGIA</h2>
                  <h1>
                    A Geraisnet investe sempre nos melhores equipamentos,
                    softwares e soluções para atendê-lo com qualidade e alta
                    performance.
                  </h1>
                </div>
                <div className="cubos">
                <div>
                    <GiCube color="#fb7c05" size={35} className="icon-home-page" />
                  </div>
                  <h2>CONFIANÇA</h2>
                  <h1>
                    Seja GeraisNet: uma empresa 100% mineira com 4 anos de mercado, presente em 3 cidades e com uma base sólida de clientes satisfeitos.
                  </h1>
                </div>
                <div className="cubos">
                <div>
                    <GiCube color="#fb7c05" size={35} className="icon-home-page" />
                  </div>
                  <h2>VELOCIDADE</h2>
                  <h1>
                    Rapidez é nosso lema. Desde a conexão digital até o atendimento ao cliente, nossa prioridade é garantir velocidade máxima.
                  </h1>
                </div>
               
              </div>
            </section>
          </div>
        </div>
      </div>
      <Rodape/>
    </div>
  );
}
