import "./areaAjuda.css";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { LuHelpingHand } from "react-icons/lu";
import { FaRegHandshake } from "react-icons/fa6";

const AreaAjuda = () => {
  return (
    <div class="help-section">
      <h1>Precisa de ajuda?</h1>
      <a href="http://geraisnet.ispfycloud.com.br/" target="_blank" >
        <div class="item">
          <div class="circle">
            <IoDocumentTextOutline size={120} color="white" />
          </div>
          <p className="circle-p">2º via de boleto</p>
        </div>
      </a>

      <a href="https://wa.me/message/5H2T5UFMH4AWJ1" target="_blank">
        <div class="item">
          <div class="circle">
            <MdOutlineSupportAgent size={120} color="white" />
          </div>
          <p className="circle-p">Atendimento</p>
        </div>
      </a>
      <a href="https://wa.me/message/5H2T5UFMH4AWJ1" target="_blank">
        <div class="item">
          <div class="circle">
            <LuHelpingHand size={120} color="white" />
          </div>
          <p className="circle-p">Suporte Técnico</p>
        </div>
      </a>
      <a href="https://wa.me/message/5H2T5UFMH4AWJ1" target="_blank">
        <div class="item">
          <div class="circle">
            <FaRegHandshake size={120} color="white" />
          </div>
          <p className="circle-p">Vendas </p>
        </div>
      </a>
    </div>
  );
};

export default AreaAjuda;
