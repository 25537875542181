import React, { useEffect, useState } from 'react';
import './styleSlide.css';
import "@fontsource/roboto";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

export default function SlideShow() {
    const [imageIndex, setImageIndex] = useState(1);
    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            // Alternar entre as imagens
            setImageIndex(prevIndex => (prevIndex % 3) + 1);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const goToSlide = (index) => {
        setImageIndex(index);
    };

    const goToPreviousSlide = () => {
        const newIndex = imageIndex === 1 ? 3 : imageIndex - 1;
        setImageIndex(newIndex);
    };

    const goToNextSlide = () => {
        const newIndex = imageIndex === 3 ? 1 : imageIndex + 1;
        setImageIndex(newIndex);
    };

    const handleMouseDown = (e) => {
        setStartX(e.pageX);
        setStartY(e.pageY);
    };

    const handleMouseMove = (e) => {
        if (!startX || !startY) return;

        const x = e.pageX;
        const y = e.pageY;

        const differenceX = startX - x;
        const differenceY = startY - y;

        if (Math.abs(differenceX) > 50 && Math.abs(differenceX) > Math.abs(differenceY)) {
            if (differenceX > 0) {
                goToNextSlide();
            } else {
                goToPreviousSlide();
            }

            setStartX(null);
            setStartY(null);
        }
    };

    const handleMouseUp = () => {
        setStartX(null);
        setStartY(null);
    };

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        setStartX(touch.pageX);
        setStartY(touch.pageY);
    };

    const handleTouchMove = (e) => {
        e.preventDefault(); // Impede a rolagem da página ao arrastar dentro do carrossel

        if (!startX || !startY) return;

        const touch = e.touches[0];
        const x = touch.pageX;
        const y = touch.pageY;

        const differenceX = startX - x;
        const differenceY = startY - y;

        if (Math.abs(differenceX) > 50 && Math.abs(differenceX) > Math.abs(differenceY)) {
            if (differenceX > 0) {
                goToNextSlide();
            } else {
                goToPreviousSlide();
            }

            setStartX(null);
            setStartY(null);
        }
    };

    return (
        <div className="slider"
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUp}
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}>
            <div className="slides">
                <input type="radio" name="radio-btn" id="radio1" checked={imageIndex === 1} onChange={() => goToSlide(1)} />
                <input type="radio" name="radio-btn" id="radio2" checked={imageIndex === 2} onChange={() => goToSlide(2)} />
                <input type="radio" name="radio-btn" id="radio3" checked={imageIndex === 3} onChange={() => goToSlide(3)} />

                <div className={`slide ${imageIndex === 1 ? 'first' : ''}`} style={{ transition: 'transform 0.5s ease' }}>
                    <img className="desktop" src={`/images/banner${imageIndex}.png`} alt={`image ${imageIndex}`} />
                    <img className="phone" src={`/images/bannerPhone${imageIndex}.png`} alt={`image ${imageIndex}`} />
                </div>
    
                <div className="navigation-auto">
                    <div className="auto-btn1" href="radio1" checked={imageIndex === 1} onClick={() => goToSlide(1)}/>
                    <div className="auto-btn2" href="radio2" checked={imageIndex === 2} onClick={() => goToSlide(2)}/>
                    <div className="auto-btn3" href="radio3" checked={imageIndex === 3} onClick={() => goToSlide(3)}/>
                </div>
            </div>
            <div className="manual-navigation">
                <button className="buttons" onClick={goToPreviousSlide}>
                    <IoIosArrowBack className='arrows'  />
                </button>
                <button className="buttons" onClick={goToNextSlide}>
                    <IoIosArrowForward className='arrows' />
                </button>
            </div>
        </div>
    );
}
